<template>
  <transition name="fade-bottom-2x">
    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
      <div v-if="visitor" class="visitor-info">
        <div class="visitor-info__heading">
          <div class="visitor-info__heading__main">
            <CompanyIcon v-if="visitor.b2b" />
            <PrivateVisitorIcon v-else />

            <div class="visitor-info__heading__main__details">
              <div class="visitor-info__heading__main__details__label">{{ visitor.b2b ? $t('vue.companyVisitor') : $t('vue.privateVisitor') }}</div>
              <div class="visitor-info__heading__main__details__title">{{ companyName }}</div>
            </div>
          </div>

          <a v-if="visitor.b2b" :href="`https://www.google.com/search?q=${visitor.name}`" target="_blank" class="visitor-info__heading__button-google"
            ><GoogleIcon class="visitor-info__heading__button-google__icon" />{{ $t('vue.searchOnGoogle') }}</a
          >

          <vs-button
            class="visitor-info__heading__button-close"
            :class="{ 'ml-auto': !visitor.b2b }"
            color="#3034F7"
            type="filled"
            icon-pack="feather"
            icon="icon-x"
            @click="closeVisitor()"
          ></vs-button>
        </div>

        <div class="visitor-info__details">
          <div class="visitor-info__details__list">
            <div class="visitor-info__details__list__item">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.country') }}</div>

              <div class="visitor-info__details__list__item__value">
                <vx-tooltip :text="countryName(visitor.country)" position="top" class="pl-0">
                  <img
                    v-if="visitor.country"
                    width="20"
                    :alt="countryName(visitor.country)"
                    :src="`https://storage.googleapis.com/pathadvice-app.appspot.com/flags/${visitor.country}.svg`"
                  />
                </vx-tooltip>
              </div>
            </div>

            <div class="visitor-info__details__list__item" style="width: 36%">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.city') }}</div>
              <div class="visitor-info__details__list__item__value">{{ visitor.city || '-' }}</div>
            </div>

            <!-- <div class="visitor-info__details__list__item" style="width: 16%;">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.totalActiveTime') }}</div>
              <div class="visitor-info__details__list__item__value">-</div>
            </div> -->

            <div class="visitor-info__details__list__item" style="width: 24%">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.firstseen') }}</div>
              <div class="visitor-info__details__list__item__value">{{ firstSeen }}</div>
            </div>

            <div class="visitor-info__details__list__item">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.lastSeen') }}</div>
              <div class="visitor-info__details__list__item__value" :class="{ 'visitor-info__details__list__item__value--online': visitor.online }">
                {{ visitor.online ? $t('vue.currentlyOnline') : lastSeen }}
              </div>
            </div>

            <div class="visitor-info__details__list__item">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.totalPageViews') }}</div>

              <div v-if="visitorPageVisits && visitorPageVisits.length > 0" class="visitor-info__details__list__item__value">{{ totalPageViews }}</div>
              <div v-else class="visitor-info__details__list__item__value">-</div>
            </div>

            <div class="visitor-info__details__list__item" style="width: 35%">
              <div class="visitor-info__details__list__item__title">{{ $t('vue.lastPageLocation') }}</div>
              <div class="visitor-info__details__list__item__value" style="word-wrap: break-word">{{ removeHttpPrefix(visitor.location) }}</div>
            </div>
          </div>

          <div class="visitor-info__details__sections">
            <div class="visitor-info__details__sections__item">
              <div class="visitor-info__details__sections__item__heading">
                <div class="visitor-info__details__sections__item__heading__icon-wrapper" style="background: #1ef8f0">
                  <StarIcon class="visitor-info__details__sections__item__heading__icon" />
                </div>

                {{ $t('vue.pageViews') }}
              </div>
              <placeholder-text-small v-if="isLoadingPageVisits" />
              <div v-else class="visitor-info__details__sections__item__body">
                <div class="visitor-info__details__sections__item__body__column">
                  <div class="visitor-info__details__sections__item__body__column__label">{{ $t('vue.page') }}</div>

                  <ul v-if="visitorPageVisits && visitorPageVisits.length > 0" class="visitor-info__details__sections__item__body__column__list">
                    <li v-for="item in visitorPageVisits" :key="item.location" class="visitor-info__details__sections__item__body__column__list__item">
                      {{ removeHttpPrefix(item.location) }}
                    </li>
                  </ul>

                  <div v-else class="visitor-info__details__list__item__value">-</div>
                </div>

                <div class="visitor-info__details__sections__item__body__column">
                  <div class="visitor-info__details__sections__item__body__column__label">{{ $t('vue.visits') }}</div>

                  <ul v-if="visitorPageVisits && visitorPageVisits.length > 0" class="visitor-info__details__sections__item__body__column__list">
                    <li
                      v-for="item in visitorPageVisits"
                      :key="`${item.location} ${item.visits}`"
                      class="visitor-info__details__sections__item__body__column__list__item"
                    >
                      {{ item.visits }}
                    </li>
                  </ul>

                  <div v-else class="visitor-info__details__list__item__value">-</div>
                </div>
              </div>
            </div>

            <div class="visitor-info__details__sections__item">
              <div class="visitor-info__details__sections__item__heading">
                <div class="visitor-info__details__sections__item__heading__icon-wrapper" style="background: #f1a342">
                  <StarIcon class="visitor-info__details__sections__item__heading__icon" />
                </div>

                {{ $t('vue.visitorInformation') }}

                <div v-if="visitor" class="visitor-info__details__sections__item__heading__device-icons">
                  <span v-html="getDeviceInfo(visitor.userAgent) ? getDeviceInfo(visitor.userAgent).browser.html : null" />
                  <span class="mx-3" v-html="getDeviceInfo(visitor.userAgent) ? getDeviceInfo(visitor.userAgent).os.html : null" />
                  <span v-html="getDeviceInfo(visitor.userAgent) ? getDeviceInfo(visitor.userAgent).platform.html : null" />
                </div>
              </div>

              <div class="visitor-info__details__sections__item__body">
                <div class="visitor-info__details__sections__item__body__column">
                  <div class="visitor-info__details__sections__item__body__column__label">{{ $t('vue.contactDetails') }}</div>

                  <div v-if="visitor.companyName">{{ visitor.companyName }}</div>
                  <div v-if="visitor.city">{{ visitor.city }}</div>
                  <div v-if="visitor.street">{{ visitor.street }}</div>
                  <div v-if="visitor.zipcode">{{ visitor.zipcode }}</div>

                  <template v-if="contactDetails">
                    <div v-if="contactDetails.name">{{ contactDetails.name }}</div>

                    <ul class="visitor-info__details__sections__item__body__column__contact">
                      <li v-if="contactDetails.phone">
                        <a :href="`tel:${contactDetails.phone}`"
                          ><VerticalPhoneIcon color="#767480" :width="16" :height="16" class="mr-1" /> {{ contactDetails.phone }}</a
                        >
                      </li>
                      <li v-if="contactDetails.email">
                        <a :href="`mailto:${contactDetails.email}`"
                          ><MailIcon color="#767480" :width="16" :height="16" class="mr-1" /> {{ contactDetails.email }}</a
                        >
                      </li>
                      <!-- <li><a href="https://pathadvice.at" target="_blank">www.pathadvice.at</a></li> -->
                    </ul>
                  </template>

                  <div
                    v-if="!visitor.companyName && !visitor.city && !visitor.street && !visitor.zipcode && !contactDetails"
                    class="visitor-info__details__list__item__value"
                  >
                    {{ $t('vue.noDetailsAvailable') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="visitor-info__details__sections__item">
              <div class="visitor-info__details__sections__item__heading">
                <div
                  class="visitor-info__details__sections__item__heading__icon-wrapper"
                  :style="`background: ${visitor.isVegaWidgetEnabled && isVisitorScores ? '#FE124E' : '#C4C4C4'};`"
                >
                  <PulseIcon v-if="visitor.isVegaWidgetEnabled" class="visitor-info__details__sections__item__heading__icon" />
                  <LockIcon v-else class="visitor-info__details__sections__item__heading__icon" color="#fff" :width="12" :height="12" />
                </div>

                {{ $t('vue.statusHistory') }}
              </div>

              <div class="visitor-info__details__sections__item__body">
                <div class="visitor-info__details__sections__item__body__column">
                  <div class="visitor-info__details__sections__item__body__column__label">{{ $t('vue.latestStatus') }}</div>

                  <VisitorStatusIconWithText
                    class="mt-3"
                    colorText="#C4C4C4"
                    :style="`width: 125px; ${visitor.isVegaWidgetEnabled && isVisitorScores ? '' : 'background: rgba(196, 196, 196, 0.2); color: blue'};`"
                    :score="visitorStatusScoreValue"
                    :online="true"
                    :isVegaWidgetEnabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="visitor-info__chart-wrapper">
          <placeholder-text-small v-show="isLoadingVisitorsScore" />
          <div id="chart-visitor-score"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import 'firebase/functions'
// eslint-disable-next-line
import firebase from 'firebase/app'

import { UAParser } from 'ua-parser-js'
// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyCkk2zrfcoNOZUOhqlsVq0fRzlG4Wgkkf8',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})
/* eslint-disable-next-line */
import VueApexCharts from 'vue-apexcharts'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

import GoogleIcon from '@/components/icons/social/Google.vue'
import CompanyIcon from '@/components/icons/CompanyIcon.vue'
import PrivateVisitorIcon from '@/components/icons/PrivateVisitorIcon.vue'
import StarIcon from '@/components/icons/StarIcon.vue'
import PulseIcon from '@/components/icons/PulseIcon.vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import VisitorStatusIconWithText from '@/components/vega/VisitorStatusIconWithText'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon'
import MailIcon from '@/components/icons/MailIcon'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

import { gen2function } from '@/mixins/gen2function'

import { mapGetters } from 'vuex'

export default {
  components: {
    /* eslint-disable-next-line */
    VueApexCharts,
    CompanyIcon,
    GoogleIcon,
    StarIcon,
    PulseIcon,
    LockIcon,
    PrivateVisitorIcon,
    VisitorStatusIconWithText,
    VerticalPhoneIcon,
    MailIcon,
    PlaceholderTextSmall
  },

  mixins: [gen2function],

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedAccountApiKeys: 'selectedAccountApiKeys'
    }),

    firstSeen() {
      if (!this.visitor || !this.visitor.firstSeen) {
        return ''
      }

      if (typeof this.visitor.firstSeen === 'string') {
        return this.formatDate(this.visitor.firstSeen)
      }

      if (typeof this.visitor.firstSeen === 'object' && this.visitor.firstSeen.seconds) {
        return this.formatDate(this.visitor.firstSeen.seconds, true)
      }

      return ''
    },
    lastSeen() {
      if (!this.visitor || !this.visitor.disconnect) {
        return ''
      }

      if (typeof this.visitor.disconnect === 'string') {
        return this.formatDate(this.visitor.disconnect)
      }

      if (typeof this.visitor.disconnect === 'object' && this.visitor.disconnect.seconds) {
        return this.formatDate(this.visitor.disconnect.seconds, true)
      }

      return ''
    },
    totalVisits() {
      return this.visitor && this.visitor.totalVisits ? this.visitor.totalVisits.toString() : '0'
    },
    companyName() {
      return this.visitor && this.visitor.companyName
        ? this.visitor.companyName
        : this.contactDetails && this.contactDetails.name
        ? this.contactDetails.name
        : this.$t('vue.noCompanyIdentified')
    },
    companyNameLinkedIn() {
      const company = this.visitor && this.visitor.name ? this.visitor.name.trim().toLowerCase() : ''
      return encodeURI(company.replace(/ /gi, '-'))
    },
    totalPageViews() {
      if (!(this.visitorPageVisits && this.visitorPageVisits.length > 0)) {
        return
      }

      return this.visitorPageVisits.reduce((accumulator, currentValue) => accumulator + currentValue.visits, 0)
    },

    contactDetails() {
      if (!this.visitor || !this.visitor.chats) {
        return null
      }

      const { chats } = this.visitor
      const sortedChats = [].concat(chats).reverse()
      const leadFormDataMessage = sortedChats.find((item) => item.leadForm)
      const contactFormDataMessage = sortedChats.find((item) => item.contactFormData && item.contactFormData.data)

      if (contactFormDataMessage) {
        const { data } = contactFormDataMessage.contactFormData

        return {
          name: data.name,
          email: data.email,
          phone: data.phone
        }
      } else if (leadFormDataMessage) {
        const { leadForm } = leadFormDataMessage

        return {
          name: leadForm.name,
          email: leadForm.email,
          phone: leadForm.phone
        }
      }

      return null
    },

    isVisitorScores() {
      return this.visitorScores && this.visitorScores.length > 0
    },

    visitorStatusScoreValue() {
      if (this.isVisitorScores && this.visitor && this.visitor.isVegaWidgetEnabled) {
        return Number(this.visitorScores[this.visitorScores.length - 1].highest_score)
      }

      return Number(this.visitorScoresDemoData[this.visitorScoresDemoData.length - 1].highest_score)
    }
  },

  data() {
    return {
      ch: null,
      countryCodes: require('@/assets/countryCodes.json'),
      visitor: {},
      isLoadingPageVisits: true,
      isLoadingVisitorsScore: true,
      visitorPageVisits: [],
      visitorScores: [],
      visitorScoresDemoData: [
        {
          highest_score: 0.1,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.18,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.54,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.36,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.34,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.18,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.33,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.7,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        },
        {
          highest_score: 0.88,
          urlPath: 'letsconnect.at',
          created: {
            value: new Date()
          }
        }
      ],
      companyInfo: null,
      showVisitor: false,
      fetchingCompanyInfo: true,
      /* prettier-ignore */

      gcpFunctionToken: null,

      colorsAndImagePoints: {
        onfire: {
          key: 'onfire',
          color: '#FF004E',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/onfire-point.svg'
        },
        hot: {
          key: 'hot',
          color: '#E00000',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/hot-point.svg'
        },
        warm: {
          key: 'warm',
          color: '#F1A342',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/warm-point.svg'
        },
        mild: {
          key: 'mild',
          color: '#FAD902',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/mild-point.svg'
        },
        cold: {
          key: 'cold',
          color: '#97F879',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/cold-point.svg'
        },
        frozen: {
          key: 'frozen',
          color: '#1EF8F0',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/frozen-point.svg'
        },
        neutral: {
          key: 'neutral',
          color: '#C4C4C4',
          imagePointUrl: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/vega/neutral-point.svg'
        }
      }
    }
  },

  created() {
    this.$serverBus.$on('get-company-info', this.onGetCompanyInfo)
  },
  async beforeMount() {
    const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
    if (idTokenResult && idTokenResult.token) {
      this.gcpFunctionToken = idTokenResult.token
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('get-company-info')
  },
  methods: {
    generateChart() {
      const isVisitorScores = this.isVisitorScores && this.visitor && this.visitor.isVegaWidgetEnabled
      const visitorScoresChartData = isVisitorScores ? this.visitorScores : this.visitorScoresDemoData

      const modifiedChartData = visitorScoresChartData.map((item) => {
        const pointData = this.getChartPoint(item.highest_score)

        return {
          ...pointData,
          created: item.created.value,
          highest_score: Number(item.highest_score),
          urlPath: this.removeHttpPrefix(item.urlPath)
        }
      })

      const modifiedVisitorScores = visitorScoresChartData.map((item) => Number(item.highest_score))
      const chartData = modifiedVisitorScores
      const formatDate = this.formatDate

      const options = {
        series: [
          {
            name: 'Visitor Scoring',
            data: chartData,
            color: modifiedChartData && isVisitorScores ? modifiedChartData[modifiedChartData.length - 1].color : '#C4C4C4'
          }
        ],

        chart: {
          type: 'area',
          height: 200,
          events: {
            mounted(chart, { globals }) {
              globals.dataLabelsRects.forEach((rects, seriesIndex) => {
                rects.forEach((rect, dataIndex) => {
                  const pointItem = modifiedChartData[dataIndex]
                  let offsetY = 10

                  if (!pointItem) {
                    return
                  }

                  if (pointItem.key === 'warm') {
                    offsetY = 13
                  } else if (pointItem.key === 'cold') {
                    offsetY = 14
                  }

                  chart.addPointAnnotation(
                    {
                      x: rect.x,
                      y: globals.series[seriesIndex][dataIndex],
                      marker: {
                        strokeWidth: 0,
                        radius: 0,
                        fillColor: 'rgba(0,0,0,0)'
                      },
                      image: {
                        offsetY,
                        path: pointItem.imagePointUrl,
                        height: 25,
                        width: 25
                      }
                    },
                    true
                  )
                })
              })
            }
          }
        },

        tooltip: {
          custom({ dataPointIndex }) {
            if (!isVisitorScores) {
              return ''
            }

            return `<div style="background: rgb(151, 151, 151); color: rgb(255, 255, 255);padding: 20px;">
                <div style="color: #DBDBDB;">Status reached:</div>
                <div style="display: flex; align-items: center;">
                  <img src="${require('@/assets/images/visitor/clock-icon.svg')}" style="margin-right: 10px;" />
                  ${formatDate(modifiedChartData[dataPointIndex].created)}
                </div>
                <div style="display: flex; align-items: center;">
                  <img src="${require('@/assets/images/visitor/map-pin-icon.svg')}" style="margin-right: 10px;" />
                  ${modifiedChartData[dataPointIndex].urlPath}
                </div>
              </div>`
          }
        },

        dataLabels: {
          enabled: true,
          style: {
            colors: ['rgba(0,0,0,0)']
          },
          formatter() {
            return ''
          }
        },

        yaxis: {
          min: 0,
          max: 1,
          show: false
        },

        stroke: {
          curve: 'smooth'
        },

        grid: {
          show: false
        }
      }

      /* eslint-disable-next-line */
      this.ch = new ApexCharts(document.querySelector('#chart-visitor-score'), options)

      if (this.ch) {
        this.ch.render()
      }
    },

    async getVisitorsScore() {
      try {
        const payload = {
          companyId: this.activeUserInfo.company,
          visitorId: this.visitor.id,
          offset: 0,
          limit: 10
        }

        this.callHttpRequestVega('bq-visitor-scores', payload, this.selectedAccountApiKeys.vega, this.$vegaEndPoint).then((visitorScores) => {
          if (visitorScores) {
            this.visitorScores = visitorScores
          }
          this.generateChart()
          this.isLoadingVisitorsScore = false
        })
      } catch (err) {
        /* eslint-disable no-console */
        console.log(err)
        this.isLoadingVisitorsScore = false
      }
    },

    async getVisitorPageVisits() {
      try {
        const config = {
          queryFilter: ` companyId="${this.activeUserInfo.company}" AND visitorId="${this.visitor.id}"`
        }

        this.callHttpRequestGen2('getvisitorpagevisits', config, this.gcpFunctionToken, this.$gen2EndPoint).then((visitorPageVisits) => {
          if (visitorPageVisits) {
            this.visitorPageVisits = visitorPageVisits
          }
          this.isLoadingPageVisits = false
        })
      } catch (err) {
        /* eslint-disable no-console */
        console.log(err)
        this.isLoadingPageVisits = false
      }
    },

    formatDate(date, isUnix) {
      if (!date) {
        return ''
      }

      if (isUnix) {
        return `${dayjs.unix(date).locale(this.$i18n.locale).format('MMM DD YYYY')} | ${dayjs.unix(date).locale(this.$i18n.locale).format('LT')}`
      }

      return `${dayjs(date).locale(this.$i18n.locale).format('MMM DD YYYY')} | ${dayjs(date).locale(this.$i18n.locale).format('LT')}`
    },

    removeHttpPrefix(inputString) {
      if (!inputString) return ''
      const regex = /^(https?:\/\/)?(www\d?\.)?/i
      const cleanUrl = inputString.replace(regex, '')

      return cleanUrl
    },

    getDeviceInfo(userAgent) {
      try {
        const { browser, os, device } = UAParser(userAgent)

        // Get browser info
        let browserName = ''
        let browserStyle = ''
        let browserHtml = ''

        if (browser.name && browser.name.toLowerCase().includes('chrome')) {
          browserName = 'Chrome'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-chrome"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('firefox')) {
          browserName = 'Firefox'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-firefox"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('safari')) {
          browserName = 'Safari'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-safari"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('edge')) {
          browserName = 'Edge'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-edge"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('internet explorer')) {
          browserName = 'Internet Explorer'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-internet-explorer"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('opera')) {
          browserName = 'Opera'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-opera"></i>'
        } else {
          browserName = browser.name || 'Unknown'
          browserStyle = 'fas'
          browserHtml = '<i class="fas fa-question"></i>'
        }

        // Get OS info
        let osName = ''
        let osStyle = ''
        let osHtml = ''

        if (os.name && os.name.toLowerCase().includes('windows')) {
          osName = 'Windows'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-windows"></i>'
        } else if (os.name && os.name.toLowerCase().includes('macos')) {
          osName = 'macOS'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-apple"></i>'
        } else if (os.name && os.name.toLowerCase().includes('ios')) {
          osName = 'iOS'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-apple"></i>'
        } else if (os.name && os.name.toLowerCase().includes('android')) {
          osName = 'Android'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-android"></i>'
        } else {
          osName = os.name || 'Unknown'
          osStyle = 'fas'
          osHtml = '<i class="fas fa-desktop"></i>'
        }

        // Get platform info
        let platformName = ''
        let platformStyle = ''
        let platformHtml = ''

        if (device.type === 'mobile') {
          platformName = 'Mobile'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-mobile"></i>'
        } else if (device.type === 'tablet') {
          platformName = 'Tablet'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-tablet"></i>'
        } else {
          platformName = 'Desktop'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-desktop"></i>'
        }

        // Return device info object
        return {
          browser: {
            name: browserName,
            style: browserStyle,
            html: browserHtml
          },
          os: {
            name: osName,
            style: osStyle,
            html: osHtml
          },
          platform: {
            name: platformName,
            style: platformStyle,
            html: platformHtml
          },
          tooltip: `Browser: ${browserName}, OS: ${osName}, Platform : ${platformName}`
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return null
      }
    },
    closeVisitor() {
      this.showVisitor = false
      this.isLoadingPageVisits = true
      this.isLoadingVisitorsScore = true
      this.$serverBus.$emit('close-company-info')
      this.companyInfo = null
      this.visitor = null
      this.visitorPageVisits = null
      this.visitorScores = null

      if (this.ch && this.ch.destory) {
        this.ch.destory()

        this.ch = null
      }
    },
    async onGetCompanyInfo(company) {
      this.$vs.loading()

      try {
        this.showVisitor = true
        this.visitor = company
        this.getVisitorsScore()
        this.getVisitorPageVisits()
        await this.getAllCompanyInfo()
      } catch (err) {
        /* eslint-disable no-console */
        console.log(err)
      }

      this.$vs.loading.close()
    },
    async getAllCompanyInfo() {
      this.fetchingCompanyInfo = true
      await this.getCompanySecondaryInfo()
      this.fetchingCompanyInfo = false
    },
    async getCompanySecondaryInfo() {
      if (this.companyInfo && !this.companyInfo.street) {
        const companyInfo = this.$functions.httpsCallable('wsCompanyInformation')
        const name = this.visitor.owner || this.visitor.isp || this.visitor.netname
        const result = await companyInfo({ name })

        if (result && result.data && this.companyInfo) {
          const event = result.data
          Object.assign(this.companyInfo, result.data)

          if (Object.keys(event).length > 0 && this.visitor.companyInfoId) {
            const companyInfoRef = await this.$db.collection('company-info').doc(this.visitor.companyInfoId)

            await companyInfoRef.set(event, {
              merge: true
            })
          }
        }
      }
    },

    countryName(countryCode) {
      const countryCodes = this.countryCodes.filter((country) => country.code === (countryCode ? countryCode.toLowerCase() : ''))
      return countryCodes && countryCodes.length > 0 ? countryCodes[0].countryName : ''
    },

    getScoring(value) {
      const score = value > 1 ? value / 10 : value
      return score ? score * 100 : null
    },

    getChartPoint(value) {
      if (!value) {
        return {
          ...this.colorsAndImagePoints.neutral,
          score: null
        }
      }

      const score = this.getScoring(value)

      if (score >= 83) {
        return {
          ...this.colorsAndImagePoints.onfire,
          score
        }
      } else if (score >= 67 && score < 83) {
        return {
          ...this.colorsAndImagePoints.hot,
          score
        }
      } else if (score >= 50 && score < 67) {
        return {
          ...this.colorsAndImagePoints.warm,
          score
        }
      } else if (score >= 33 && score < 50) {
        return {
          ...this.colorsAndImagePoints.mild,
          score
        }
      } else if (score >= 17 && score < 33) {
        return {
          ...this.colorsAndImagePoints.cold,
          score
        }
      } else {
        return {
          ...this.colorsAndImagePoints.frozen,
          score
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.visitor-info {
  background: rgb(255, 255, 255);

  &__heading {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background: rgb(235, 246, 254);

    &__main {
      display: flex;
      align-items: center;

      &__details {
        margin-left: 15px;

        &__label {
          color: rgb(48, 52, 247);
        }

        &__title {
          color: rgb(38, 38, 41);
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    &__button {
      &-google {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding: 9px 20px;
        color: rgb(48, 52, 247);
        font-weight: 700;
        background: rgb(224, 225, 254);
        border: 1px solid rgb(48, 52, 247);
        border-radius: 6px;

        &:hover {
          background: rgba(224, 225, 254, 0.7);
          transition: all 0.3s linear;
        }

        &__icon {
          margin-right: 15px;
          width: 22px;
          height: 22px;
        }
      }

      &-close {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  &__details {
    padding: 10px 30px;

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      &__item {
        margin-bottom: 30px;
        width: 20%;
        min-width: 120px;

        &__title {
          color: rgb(194, 192, 197);
        }

        &__value {
          color: rgb(118, 116, 128);
          font-size: 18px;
          font-weight: 700;

          &--online {
            &::before {
              content: '';
              margin-right: 10px;
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #2bff6f;
              border-radius: 100%;
            }
          }
        }
      }
    }

    &__sections {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1024px) {
        flex-direction: column;

        &__item {
          width: 100% !important;
        }
      }

      &__item {
        margin-top: 40px;
        width: 56%;

        &:nth-of-type(2n) {
          width: 44%;
        }

        &__heading {
          display: flex;
          align-items: center;
          color: rgb(38, 38, 41);
          font-size: 20px;
          font-weight: 700;

          &__icon {
            &-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              width: 25px;
              height: 25px;
              border-radius: 6px;
            }
          }

          &__device-icons {
            margin-left: auto;
            width: calc(45% + 2px);
            color: rgb(98, 98, 98);
            font-size: 16px;
          }
        }

        &__body {
          display: flex;
          flex-wrap: wrap;

          &__column {
            width: 56%;
            color: rgb(118, 116, 128);
            font-size: 18px;
            font-weight: 700;

            &:nth-of-type(2n) {
              width: 44%;
            }

            a {
              color: rgb(118, 116, 128);
              font-weight: 700;

              &:hover {
                text-decoration: underline;
              }
            }

            &__label {
              margin-top: 20px;
              color: rgb(194, 192, 197);
              font-size: 16px;
              font-weight: 400;
            }

            &__list {
              margin-top: 5px;

              &__item {
                margin-top: 10px;
              }
            }

            &__contact {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  &__chart-wrapper {
    position: relative;
    margin-left: 30px;
    width: calc(100% - 60px);
  }
}

.visitor-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  .material-icons {
    font-size: 3rem;
  }
  &._prev {
    &:hover {
      transition: 0.3s ease-in-out;
      transform: translateX(-6px);
    }
  }
  &._next {
    &:hover {
      transition: 0.3s ease-in-out;
      transform: translateX(6px);
    }
  }
}
.bg-gray {
  background-color: gray;
}
.bg-lightGray {
  background-color: #f2f2f2;
}

.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.vs-list--header,
.vs-list--item {
  overflow-wrap: anywhere;
}

.vs-image {
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
}
.company-image {
  display: block;
  margin-top: 20px;
  margin-right: 20px;
}
.company-image .con-vs-image {
  border-radius: 0px !important;
}
.company-image .vs-image--img {
  background-size: cover;
  border-radius: 0px !important;
  min-width: 10px;
}
.vs-image .con-vs-image {
  overflow: visible;
}
.vs-image--img {
  background: no-repeat;
}
</style>

<style lang="scss">
.apexcharts-toolbar,
.apexcharts-xaxis,
.apexcharts-xaxistooltip-bottom {
  display: none !important;
}
</style>
